import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/Hero/Hero";
import Description from "../components/Description/Description";

import videoMakingVideoHevc from "../videos/video_making/video-making-h265.mp4";
import videoMakingVideoWebm from "../videos/video_making/video-making-vp9.webm";
import videoMakingVideoMp4 from "../videos/video_making/video-making-h264.mp4";
import videoMakingPoster from "../videos/video_making/video-making-poster.jpg";

export const videoImage = graphql`
  fragment videoImage on File {
    childImageSharp {
      fluid(maxHeight: 1200, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

const VideoMaking = ({ data }) => (
  <Layout pageUrl="video-making">
    <SEO title="Video" />
    <Hero
      home={false}
      pageTitle="Video Making"
      videoSrcHevc={videoMakingVideoHevc}
      videoSrcWebm={videoMakingVideoWebm}
      videoSrcMp4={videoMakingVideoMp4}
      videoPoster={videoMakingPoster}
    />
    <Description
      heading="The right video to represent your brand"
      description="Our Video Making service allows you to shoot the perfect videos based on new image trends, optimizing production time and costs. The Parallel Milano team can guarantee the realization of small and medium productions for campaigns, social media, e-commerce and backstage, also providing an accurate video editing service."
      noPics
    />
  </Layout>
);

export default VideoMaking;
